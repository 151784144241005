<template>
  <div class="flex flex-col gap-7 border border-neutral-200 rounded-lg p-5 max-w-md w-full">
    <div class="flex justify-between gap-10">
      <div class="flex gap-2 items-start">
        <div class="mr-2.5">
          <router-link :to="{ path: `/goldrush/raffle?eventId=${event.id}` }">
            <p class="text-slate-700 text-xl underline">Draw #{{ event.drawNumber }}</p>
          </router-link>
          <p class="text-sm text-gray-600">{{ winnerPicked ? 'Previous Draw' : 'Current Draw' }}</p>
        </div>
        <EventStatusBadge v-if="!winnerPicked" :status="event.status" />
        <Badge v-if="isRollover" variant="default" label="Rollover" />
        <Badge v-if="!isRollover && winnerPicked" variant="secondary" label="Winner" />
      </div>
      <p class="text-sm text-gray-600">
        {{ event.status === 'active' ? 'To be drawn on' : 'Drawn on' }} {{ formattedDate(event.drawDate) }}
      </p>
    </div>
    <div class="flex gap-16">
      <div>
        <p v-if="event.winningTicket" class="text-lg font-medium">
          {{ isRollover ? 'Non-Winner' : 'Winner' }} Information
        </p>
        <p v-else class="text-lg font-medium">Winner Information</p>
        <div v-if="event.winningTicket" class="flex gap-5 mt-3">
          <p>{{ formattedTicketNumber() }}</p>
          <p v-if="event.winningTicket">{{ event.winningTicket.winnerName }}</p>
        </div>
        <p v-else class="text-sm text-gray-600 italic mt-3">Winner not yet picked</p>
      </div>
      <div>
        <p class="text-lg font-medium">Jackpot</p>
        <p class="text-xl font-bold mt-2">{{ formatCurrency(event.jackpotCents / 100) }}</p>
        <p class="italic text-sm mt-2">Winner takes</p>
        <p class="mt-0.5">{{ formatCurrency(Math.floor(event.jackpotCents / 100 / 2)) }}</p>
      </div>
    </div>
    <div class="flex gap-8">
      <div>
        <p>
          <span class="font-bold text-lg mr-1.5">{{ event.totalOrders }}</span>
          <span class="text-gray-600 text-md">orders</span>
        </p>
      </div>
      <div>
        <p>
          <span class="font-bold text-lg mr-1.5">{{ event.totalTickets }}</span>
          <span class="text-gray-600 text-md">tickets sold</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import EventStatusBadge from '@/components/EventStatusBadge';
import Badge from '@/components/rbComponents/Badge';
import { format } from 'date-fns';

export default {
  props: {
    event: { required: true }
  },
  components: {
    EventStatusBadge,
    Badge
  },
  computed: {
    winnerPicked() {
      return this.event.winningTicket;
    },
    isRollover() {
      return this.event.winningTicket?.rollover;
    }
  },
  methods: {
    formattedDate(date) {
      return format(this.parseISO(date), 'dd/MM/yyyy');
    },
    formattedTicketNumber() {
      return this.event.winningTicket?.fullTicketNumber.substr(2);
    }
  }
};
</script>
